@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Anek';
    src: url('./assets/fonts/AnekLatin_Expanded-Bold.ttf') format('woff');
    font-weight: 800;
  }

  @font-face {
    font-family: 'Anek';
    src: url('./assets/fonts/AnekLatin-Bold.ttf') format('woff');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Anek';
    src: url('./assets/fonts/AnekLatin-Regular.ttf') format('woff');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Regular.ttf') format('woff');
    font-weight: 400;
  }

  body {
    @apply bg-black text-white;
    font-family: 'Anek', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Anek', sans-serif;
  }
}
